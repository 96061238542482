import calculatedNewPrice from './calculatedNewPrice'
import statusDpoService from './statusDpoService'
import mountDpoService from './mountDpoService'

const PaymentContract = {
  calculatedNewPrice,
  statusDpoService,
  mountDpoService,
}

export default PaymentContract
