import { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { size } from 'lodash'
import moment from 'moment'

import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core'
import { Lock as LockIcon } from '@material-ui/icons'

import PropTypes from 'prop-types'

import {
  DelinquencyTable,
  MeetingStep,
  ResultStep,
  SolicitationStep,
} from './components'

import { routes } from 'Routes'
import { useSnackbar } from 'hooks'
import constants from 'constants/index'
import theme from 'theme'

import useStyles from './styles'
import { Alert } from '@material-ui/lab'

const ChurnStepper = ({ churn, setLoading, show = false }) => {
  const [activeStep, setActiveStep] = useState(0)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const isReturn = activeStep === 0

  const churnDate = churn?.churnMeeting?.date
  const isFutureDate = churnDate
    ? moment(churnDate).isAfter(moment(), 'day')
    : false

  const handleBackPage = () => history.push(routes.churns.all)

  const handleNextStep = () => {
    if (activeStep === 1 && isFutureDate) {
      snackbar.open({
        message: `É possível avançar somente depois que ocorrer a reunião`,
        variant: 'info',
      })

      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBackStep = () => {
    if (isReturn) handleBackPage()
    else setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const stepContent = {
    0: (
      <SolicitationStep
        churn={churn}
        setLoading={setLoading}
        handleNextStep={handleNextStep}
        show={show}
      />
    ),
    1: (
      <MeetingStep
        churn={churn}
        setLoading={setLoading}
        isFutureDate={isFutureDate}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        handleBackPage={handleBackPage}
        show={show}
      />
    ),
    2: (
      <ResultStep
        churn={churn}
        setLoading={setLoading}
        handleNextStep={handleNextStep}
        show={show}
      />
    ),
    3: (
      <DelinquencyTable
        churnId={churn?.id}
        handleBackPage={handleBackPage}
        button
      />
    ),
  }

  const isDestroyed =
    !churn?.paymentContract?.active ||
    churn?.preRegistration?.status ===
      constants.preRegistration.SOFT_DELETED_STATUS

  return (
    <Fragment>
      <Box className={classes.container}>
        {isDestroyed && (
          <Box mb={4}>
            <Alert variant="filled" severity="error">
              <Typography variant="body1">
                Atenção! O contrato ou cliente foi excluído. Algumas
                funcionalidades podem não funcionar corretamente, caso necessite
                da recuperação do contrato ou cliente, entre em contato com o
                setor de TI.
              </Typography>
            </Alert>
          </Box>
        )}
        <Stepper activeStep={activeStep} alternativeLabel>
          {constants.churn.SOLICITATION_FORM_STEPS.map((step, index) => (
            <Step key={index}>
              <StepLabel
                icon={
                  (index === 2 || index === 3) && isFutureDate ? (
                    <LockIcon />
                  ) : (
                    index + 1
                  )
                }
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {stepContent[activeStep]}
        {activeStep !== 1 && activeStep !== 3 && (
          <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={theme.spacing(2)}
          >
            {(activeStep === 0 || show) && (
              <Button variant="contained" onClick={handleBackStep}>
                Voltar
              </Button>
            )}
            {show ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
              >
                Avançar
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form={constants.churn.DEFINITION_CHURN_STEPPER_FORM[activeStep]}
              >
                {activeStep ===
                  size(constants.churn.SOLICITATION_FORM_STEPS) - 1 ||
                activeStep === 1
                  ? 'Salvar'
                  : 'Avançar'}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

ChurnStepper.propTypes = {
  churn: PropTypes.object,
  setLoading: PropTypes.func,
  show: PropTypes.bool,
}

export default ChurnStepper
