import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const PermissionsForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const clientProfileLists = constants.permissionsLists.clientProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Permissões</Typography>
      </Box>
      <BasePermissionsForm
        title="Departamentos"
        permissionsList={clientProfileLists.departmentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Usuários"
        permissionsList={clientProfileLists.userList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Incidentes"
        permissionsList={clientProfileLists.incidentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Processos"
        permissionsList={clientProfileLists.dataProcessList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
        noOverFlow
      />
      <BasePermissionsForm
        title="Registros de melhoria contínua"
        permissionsList={clientProfileLists.nonComplianceReportList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
        noOverFlow
      />
      <BasePermissionsForm
        title="Questionários"
        permissionsList={clientProfileLists.questionnaireList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Controle (questão)"
        permissionsList={clientProfileLists.diagnosticsList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Perfis"
        permissionsList={clientProfileLists.profileList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Relatório de Impacto à Proteção de Dados"
        permissionsList={clientProfileLists.ripdList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Minha LGPD"
        permissionsList={clientProfileLists.dashboardList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
        noOverFlow
      />
      <BasePermissionsForm
        title="Dados cadastrais da empresa"
        permissionsList={clientProfileLists.companyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
        noOverFlow
      />
      <BasePermissionsForm
        title="Fornecedores"
        permissionsList={clientProfileLists.companySupplierList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Políticas de Privacidade"
        permissionsList={clientProfileLists.privacyPolicyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
        noOverFlow
      />
      <BasePermissionsForm
        title="Documento"
        permissionsList={clientProfileLists.documentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Atualizações"
        permissionsList={clientProfileLists.lastUpdatesList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Modelos de Documento"
        permissionsList={clientProfileLists.documentTemplateList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Dados financeiros"
        permissionsList={clientProfileLists.financialList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Canal de denúncias"
        permissionsList={clientProfileLists.complaintList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
    </>
  )
}

PermissionsForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default PermissionsForm
