import Moment from 'moment'

import helpers from 'helpers'

const mount = (data) => {
  return {
    ...data,
    price: helpers.formatters.moneyToString(data.price),
    paidDate: data.paidDate ? Moment(data.paidDate).format('YYYY-MM-DD') : null,
    withdrawalDate: data.withdrawalDate
      ? Moment(data.withdrawalDate).format('YYYY-MM-DD')
      : null,
    orderReferenceDate: Moment(data.orderReferenceDate)
      .endOf('month')
      .format('YYYY-MM-DD'),
    expirationDate: data.expirationDate
      ? Moment(data.expirationDate).format('YYYY-MM-DD')
      : null,
  }
}

export default mount
